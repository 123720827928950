@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css');

body {
	font-family: sans-serif;
}

.screenshot {
	padding: 5px;
}

/* TILES */

p, span, a, ul, li, button {
    font-family: inherit;
    font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
}

strong {
	font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Open Sans', "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serify;
	line-height: 1.5em;
	font-weight: 300;	
}

strong {
  font-weight: 400;
}

.tile {  
    width: 100%;
    display: inline-block;
	box-sizing: border-box;
	background: #fff;		
	padding: 20px;
	margin-bottom: 30px;
} 

 .title {
    	margin-top: 0px;
  }

.purple, .blue, .red, .orange, .green {
    color: #fff;
  }
  
.purple {
    background: #5133AB;
}

 .purple:hover {
    background: darken(#5133AB, 10%);
 }	
 
.red { background: #AC193D;}

.red:hover {
    background: darken(#AC193D, 10%);
}		


.green {background: #00A600;}

 .green:hover {
	background: darken(#00A600, 10%);
}		


.blue {   background: #2672EC;}

.blue:hover {
	background: darken(#2672EC, 10%);
}	

div.blue:hover {
	filter: brightness(90%);
}

.orange {  background: #DC572E;}

.orange:hover {
	background: darken(#DC572E, 10%);
}
